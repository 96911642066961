import React, { useEffect, useState } from "react";

import { PrimaryButton } from "Components/Button";
import { DatePicker, FormHandler } from "Components/Form";
import { ModalTrigger } from "Components/Modal";

import EditMarsInformation from "./Modals/EditMarsInformation";
import field from "Services/Api/Admin/Cms/Elements/Field";

const Mars = ({ handleInput, form, service_user_id: serviceUserId }) => {
    const [lastUpdated, setLastUpdated] = useState(new Date().toISOString());
    const [fixedDates, setFixedDates] = useState(true);

    const startFixedDate='2022-08-26';

    const dateDown = () => {
        if(!form.date)return;

        let current = new Date(form.date);
        current.setDate(current.getDate()-28);

        if (current < new Date(startFixedDate))
            current = new Date(startFixedDate);

        handleInput("date", current ?? new Date());
    }

    const dateUp = () => {
        if(!form.date)return;

        let current = new Date(form.date);

        current.setDate(current.getDate()+28);

        handleInput("date", current ?? new Date());
    }
    const setFixedDays = () => {
        if(!form.date)return;

        setFixedDates(!fixedDates);

        if (!fixedDates) {
            handleInput ( "date", new Date ( startFixedDate ) );
        } else {
            handleInput ( "date", new Date (  ) );
        }
    }

    useEffect(() => {
        let date = new Date(startFixedDate);

        let latestStartDate = new Date(startFixedDate);

        while (date <= new Date()) {
            latestStartDate = new Date(date);
            date.setDate(date.getDate() + 28);
        }

        handleInput("date", latestStartDate);
    }, []);

    const handleRefresh = () => setLastUpdated(new Date().toISOString());

    const selectDate = (date) => {
        if(!form.date)return;

        let fixedDate= new Date(date);
        let dateLoop = new Date(startFixedDate);
        let returnDate;

        //Only attempt to find the selected 28 date range if user has asked for fixed dates
        if(fixedDates) {
            //reset date if before start of fixed date range
            if(date < new Date ( startFixedDate )){
                date = startFixedDate
            }

            //To work out which 28 day window the user has selcted we have to loop through 28 day increments from startFixedDate 26 aug 2022
            //Once we find the date range we need to refresh the calender with the start of the 28 day window
            while ( dateLoop >= new Date ( startFixedDate ) && dateLoop <= new Date () ) {

                if ( fixedDate > dateLoop && fixedDate.getDate () <= dateLoop.getDate () + 28 ) {
                    returnDate = dateLoop;
                    handleInput ( "date", new Date ( returnDate ?? date ) );
                    //return;
                }
                dateLoop.setDate ( dateLoop.getDate () + 28 );
            }
        } else {
            handleInput ( "date", new Date ( date ) );
        }

    }
    return (
        <div>
            <div className="flex space-x-4">
                <div className="flex-1">
                    <DatePicker
                        selected={form.date}
                        onChange={(date) => selectDate(date)}
                    />
                    <div className={"flex flex-row my-4 gap-4"}>
                        {/*<PrimaryButton onClick={setFixedDays} text="Fixed Date Mode" />*/}
                        <PrimaryButton onClick={dateDown} text="Previous Sheet" disabled={!fixedDates}/>
                        <PrimaryButton onClick={dateUp} text="Next Sheet" disabled={!fixedDates} />
                    </div>
                </div>

                <ModalTrigger
                    component={EditMarsInformation}
                    props={{
                        serviceUserId,
                        handleRefresh,
                    }}
                >
                    <PrimaryButton
                        text="Edit Information"
                    />
                </ModalTrigger>
            </div>

            {form.date && (
                <iframe
                    src={`/api/v1/service-users/${serviceUserId}/mars?version=${lastUpdated}&date=${form.date?.toISOString()}`}
                    // type="application/pdf"
                    className="w-full max-w-4xl mx-auto h-[60rem] mt-4"
                />
            )}
        </div>
    );
};

export default FormHandler(Mars);
