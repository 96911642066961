import React from 'react';
import {Redirect} from 'react-router-dom';

import User from 'Services/User';

/**
 * @function Group
 * @param {*|JSX.Element} component
 * @param {string[]} parameters
 * @return {*|JSX.Element}
 * @constructor
 */
const Group = ({component, parameters}) => {
    const result = User.loggedIn && User.belongsToGroup(parameters[0]);

    return result ? component : <Redirect to="/" />;
};

export default Group;
