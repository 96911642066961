import React from 'react';

import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import Authenticated from 'Components/Layouts/Authenticated';
import { Loading } from 'Components/Partials';
import ViewCmsElementRecords from 'Components/Utilities/ViewCmsElementRecords';

import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';
import { filterItemsByAccess } from 'Services/BaseHelpers';

import FamilyTab from './Tabs/Family';
import TimesheetsTab from './Tabs/Timesheets';
import NarrativeScheduleTab from './NarrativeSchedule';
import NarrativesTab from './Tabs/Narratives';
import CalendarTab from './Tabs/Calendar';
import MedicationsTab from './Medications';
import MarsTab from './Tabs/Mars';
import ArchiveUserApi from "Services/Api/Admin/ArchiveUser";
import Toast from "Services/Toast";
import { DangerButton, PrimaryButton } from "Components/Button";
import CareDocumentsTab from "Pages/ServiceUsers/Tabs/CareDocumentsTab";

export default class ServiceUsersShow extends React.Component {
    /**
     * @var tabs
     * @type {array}
     */
    tabs = [
        {
            title: 'Narratives',
            method: () => this.renderNarratives()
        },
        {
            title: 'Care Documents',
            method: () => this.renderCareDocuments(),
        },
        // {
        //     title: 'Profile',
        //     method: () => this.renderProfile()
        // },
        {
            title: 'Family',
            method: () => this.renderFamily()
        },
        {
            title: 'Timesheets',
            method: () => this.renderTimesheets()
        },
        {
            title: 'Calendar',
            method: () => this.renderCalendar()
        },
        {
            title: 'Narrative Schedule',
            method: () => this.renderNarrativeSchedule(),
            is_admin: true
        },
        {
            title: 'Medication Schedule',
            method: () => this.renderMedications(),
            is_admin: true
        },
        {
            title: 'MARS',
            method: () => this.renderMars(),
            is_admin: true
        },
        {
            title: 'Narrative History',
            method: () => this.renderNarrativeHistory()
        },
        {
            title: 'Archive',
            method: () => this.renderArchive(),
            is_admin: true,
        }
    ];

    /**
     * @var state
     */
    state = {
        service_user_id: this.props.match?.params?.user ?? '',
        working: true,
        activeTab: 0,
        user: null,
    };

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.loadUser();
    }

    /**
     * @method loadUser
     */
    loadUser = async () => {
        const { service_user_id } = this.state;

        const response = await ServiceUsersApi.get(service_user_id);

        this.setState({
            working: false,
            user: response.data.data,
        });
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.state;

        return (
            <Authenticated title={ user?.full_name } titleIcon={ faUsers }>
                { this.renderTabsContainer() }
            </Authenticated>
        )
    }

    /**
     * @method renderTabsContainer
     * @return {JSX.Element}
     */
    renderTabsContainer() {
        const { service_user_id, activeTab } = this.state;

        let tabs = filterItemsByAccess(this.tabs);

        return (
            <div className="flex flex-col h-full">
                <div className="flex bg-app-light-blue text-app-grey font-bold select-none">
                    { tabs.map((tab, key) => (
                        <div
                            className="px-4 cursor-pointer uppercase"
                            onClick={ () => this.setState({ activeTab: key }) }
                            key={ key }
                        >
                            <div className={ activeTab === key ? 'border-b-4 border-app-leading p-2' : 'p-2' }>
                                { tab.title }
                            </div>
                        </div>
                    )) }
                </div>

                <div className={ `p-6 bg-gray-300 ${ tabs[activeTab].title === 'Calendar' ? 'pt-0' : '' }` }>
                    { tabs[activeTab].method() }
                </div>
            </div>
        );
    }

    /**
     * @method renderProfile
     * @return {JSX.Element}
     */
    renderProfile() {
        const { service_user_id, working, user } = this.state;

        let headerCell = 'bg-gray-300 px-4 py-2 border border-app-leading text-center';
        let cell = 'px-4 py-2 border border-app-leading text-center';

        if (working) {
            return <Loading/>;
        }

        return (
            <table className="table-fixed mx-auto">
                <thead>
                <tr>
                    <th className={ headerCell }>First Name</th>
                    <th className={ headerCell }>Last Name</th>
                    <th className={ headerCell }>Enabled</th>
                    <th className={ headerCell }>Created At</th>
                    <th className={ headerCell }>Notes</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={ cell }>{ user.first_name }</td>
                    <td className={ cell }>{ user.last_name }</td>
                    <td className={ cell }>{ user.enabled ? 'Yes' : 'No' }</td>
                    <td className={ cell }>{ user.created_at.date_time_timezone }</td>
                    <td className={ cell }>{ user.notes }</td>
                </tr>
                </tbody>
            </table>
        );
    }

    // Add a new method to render the Care Documents tab
    renderCareDocuments() {
        const { user } = this.state;

        return (
            <CareDocumentsTab
                user={ user }
            />
        );
    }

    /**
     * @method renderFamily
     * @return {JSX.Element}
     */
    renderFamily() {
        const { service_user_id } = this.state;

        return (
            <FamilyTab
                service_user_id={ service_user_id }
            />
        );
    }

    /**
     * @method renderTimesheets
     * @return {JSX.Element}
     */
    renderTimesheets() {
        const { service_user_id } = this.state;

        return (
            <TimesheetsTab
                service_user_id={ service_user_id }
            />
        );
    };

    /**
     * @method renderNarrativeSchedule
     * @return {JSX.Element}
     */
    renderNarrativeSchedule() {
        const { service_user_id } = this.state;

        return (
            <NarrativeScheduleTab
                service_user_id={ service_user_id }
            />
        );
    };

    /**
     * @method renderNarratives
     * @return {JSX.Element}
     */
    renderNarratives() {
        const { service_user_id } = this.state;

        return (
            <NarrativesTab
                service_user_id={ service_user_id }
            />
        );
    };

    /**
     * @method renderCalendar
     * @return {JSX.Element}
     */
    renderCalendar() {
        const { service_user_id } = this.state;

        return (
            <CalendarTab
                service_user_id={ service_user_id }
            />
        );
    };

    /**
     * @method renderMedications
     * @return {JSX.Element}
     */
    renderMedications() {
        const { service_user_id } = this.state;

        return (
            <MedicationsTab
                service_user_id={ service_user_id }
            />
        );
    };

    /**
     * @method renderNarrativeHistory
     * @return {JSX.Element}
     */
    renderNarrativeHistory() {
        const { service_user_id } = this.state;

        return (
            <ViewCmsElementRecords
                service_user_id={ service_user_id }
                attach_to_service_user={ true }
            />
        );
    };

    /**
     * @method renderMars
     * @return {JSX.Element}
     */
    renderMars = () => {
        const { service_user_id } = this.state;

        return (
            <MarsTab
                service_user_id={ service_user_id }
            />
        );
    }

    /**
     * @method renderArchive
     */
    renderArchive = () => {
        const { user, service_user_id } = this.state;

        if (user?.is_archived) {
            return (
                <div>
                    User is archived
                </div>
            );
        }

        const archive = async () => {
            const { success } = await ArchiveUserApi.patch({
                service_user_id,
            }, { archived: true });

            if (success) {
                Toast.success("User archived successfully");
            }
        };

        return (
            <div>
                <p className="mb-4">Are you sure you want to archive this user?</p>

                <div className="flex items-center w-full">
                    <DangerButton
                        onClick={archive}
                        text="Archive"
                    />
                </div>
            </div>
        );
    };
}
