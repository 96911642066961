import {BooleanFilter, MultipleChoiceFilter, DateTimeFilter} from 'Components/Filters';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';
import Delete from 'Components/Partials/Modals/Delete';

import NetworkingModal from 'Pages/Networking/Modal';

import RecordApi from 'Services/Api/Cms/Elements/Record';

import {translation} from 'Services/TranslationHelpers';
import {translationFromJson} from 'Services/TranslationHelpers2';
import User from 'Services/User';

/**
 * @method getRecordValue
 * @param {object} record
 * @param {string} field_identifier
 * @param {string} field_type
 * @return {mixed}
 */
const getRecordValue = (record, field_identifier, field_type = null) => {
    let value_model = record.values.filter(o => o.field.identifier === field_identifier);

    if (!value_model || value_model.length === 0) {
        return null;
    }

    let value = value_model[0].value;

    if (field_type) {
        if (field_type === 'date_time') {
            value = value?.date_time_timezone;
        } else if (field_type === 'date') {
            value = value?.date_timezone;
        } else if (field_type === 'time') {
            value = value?.time_timezone;
        } else if (field_type === 'boolean') {
            value = value ? 'Yes' : 'No';
        } else if (field_type === 'file') {
            value = value?.url;
        }
    }

    return value;
}

/**
 * @method getFieldFilter
 * @return {array} fields
 * @return {array}
 */
const getFieldsFilter = (fields) => {
    let filters = [];

    let columns = Object.entries(fields).map((data, j) => {
        let new_filters = getFieldFilter(data[1]);

        filters = [...filters, ...new_filters];
    });

    return filters;
}

/**
 * @method getFieldFilter
 * @return {object} field
 * @return {array}
 */
const getFieldFilter = (field) => {
    let filters = [];

    if (field.type === 'boolean') {
        filters = [
            {
                column: field.id,
                label: translationFromJson(field.label),
                component: BooleanFilter
            }
        ];
    } else if (field.type === 'multiple_choice_single_value') {
        filters = [
            {
                column: field.id,
                label: translationFromJson(field.label),
                component: MultipleChoiceFilter,
                props: {
                    options: field.multiple_choice_values.map((value, key) => {
                        let translation_value = translation('misc', value, null, false);

                        return {
                            label: translation_value && translation_value !== '' ? translation_value : value,
                            value: value
                        };
                    })
                }
            }
        ];
    } else if (field.type === 'date' || field.type === 'time') {
        filters = [
            {
                column: field.id,
                label: translationFromJson(field.label) + ' Before',
                component: DateTimeFilter,
                props: {
                    type: field.type,
                    direction: 'before'
                }
            },
            {
                column: field.id,
                label: translationFromJson(field.label) + ' After',
                component: DateTimeFilter,
                props: {
                    type: field.type,
                    direction: 'after'
                }
            }
        ];
    }

    return filters;
}

/**
 * @method getRowActions
 * @param {string} selected_element_id
 * @param {object} item
 */
const getRowActions = (selected_element_id, item) => {
    let buttons = [
        {
            type: "modalTrigger",
            component: CreateCmsElementRecordModal,
            props: {
                showElementsDropdown: false,
                element_id: selected_element_id,
                element_record_id: item.id,
            },
            text: 'View/Update'
        }
    ];

    if (User.data.is_admin) {
        buttons.push({
            type: "modalTrigger",
            component: Delete,
            props: {
                itemName: 'item',
                itemId: item.id,
                onDeleteApi: RecordApi.delete
            },
            text: 'Delete'
        });
    }

    buttons.push(
        {
            type: "modalTrigger",
            component: CreateCmsElementRecordModal,
            props: {
                showElementsDropdown: false,
                element_id: selected_element_id,
                element_record_id: item.id,
                clone: true
            },
            text: 'Clone'
        },
        {
            type: "modalTrigger",
            component: NetworkingModal,
            props: {
                selectedMessageToType: 'App\\Models\\CmsElementRecord',
                selectedMessageToId: item.id,
                additional_key: 'staff-and-family'
            },
            text: 'Staff and Family Comments'
        }
    );

    if (getRecordValue(item, 'service_user_can_comment')) {
        buttons.push({
            type: "modalTrigger",
            component: NetworkingModal,     
            props: {
                selectedMessageToType: 'App\\Models\\CmsElementRecord',
                selectedMessageToId: item.id,
                additional_key: 'service-user'
            },
            text: "Service User's Comments"
        });
    }

    return buttons;
}

export {
    getRecordValue,
    getFieldsFilter,
    getFieldFilter,
    getRowActions
}
