import React from 'react';

import Delete from 'Components/Partials/Modals/Delete';
import {IndexTable} from 'Components/Utilities';

import NarrativeScheduleApi from 'Services/Api/ServiceUsers/NarrativeSchedule';
import {translationFromJson} from 'Services/TranslationHelpers2';

import Store from './store';

export default class Index extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'Narrative',
            value: (record) => translationFromJson(record?.cms_element?.label),
            type: 'callback',
            orderable: null
        },
        {
            label: 'Time',
            value: 'time',
            type: 'string',
            orderable: 'time',
            valueClassName: 'capitalize'
        }
    ];

    /**
     * @method getRowActions
     * @param {object} item
     */
    getRowActions = (item) => {
        const {service_user_id} = this.props;

        return [
            {
                type: "modalTrigger",
                component: Store,
                props: {
                    service_user_id,
                    narrativeSchedule: item
                },
                text: 'Update'
            },
            {
                type: "modalTrigger",
                component: Delete,
                props: {
                    itemName: 'narrative schedule',
                    itemId: item.id,
                    onDeleteApi: NarrativeScheduleApi.delete
                },
                text: 'Delete'
            }
        ];
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id} = this.props;

        return (
            <IndexTable
                columns={this.columns}
                defaultOrder={{
                    order_by: 'time',
                    order: 'asc'
                }}
                displaySearchBar={false}
                storeModal={{
                    component: Store,
                    props:{
                        service_user_id
                    }
                }}
                loadDataCallback={(data) => NarrativeScheduleApi.get(null, {
                    ...data,
                    service_user_id,
                    paginate: true
                })}
                rowActions={this.getRowActions}
                eventApi={NarrativeScheduleApi}
                actionsJustify="center"
            />
        )
    }
}
