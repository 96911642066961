import React from 'react';
import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';

import Authenticated from 'Components/Layouts/Authenticated';
import {IndexTable} from 'Components/Utilities';
import {BooleanFilter} from 'Components/Filters';

import ServiceUsersApi from 'Services/Api/ServiceUsers/ServiceUsers';

export default class ServiceUsersIndex extends React.Component {
    /**
     * @var columns
     * @type {array}
     */
    columns = [
        {
            label: 'First Name',
            value: 'first_name',
            type: 'string',
            orderable: 'first_name'
        },
        {
            label: 'Last Name',
            value: 'last_name',
            type: 'string',
            orderable: 'last_name'
        },
        {
            label: 'Property',
            value: 'is_property',
            type: 'boolean',
            orderable: 'is_property',
            filters: [
                {
                    label: 'Property',
                    column: 'is_property',
                    component: BooleanFilter
                }
            ]
        }
    ];

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        return (
            <Authenticated title="Service Users" titleIcon={faUsers}>
                <IndexTable
                    columns={this.columns}
                    defaultOrder={{
                        order_by: 'first_name',
                        order: 'asc'
                    }}
                    defaultFilters={{
                        'is_property[boolean]': 'No'
                    }}
                    displaySearchBar={true}
                    loadDataCallback={(data) => ServiceUsersApi.get(null, data)}
                    rowClickRedirect={(item) => "/service-users/show/"+item.id}
                    eventApi={ServiceUsersApi}
                />
            </Authenticated>
        )
    }
}
