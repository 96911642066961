import React from 'react';
import { DateTime } from 'luxon';

import {PrimaryButton, SecondaryButton} from 'Components/Button';
import {ModalTrigger} from 'Components/Modal';
import CreateCmsElementRecordModal from 'Components/Partials/Modals/CreateCmsElementRecordModal';
import {DatePicker} from 'Components/Form';
import {Loading, ActionsButton} from 'Components/Partials';

import MedicationTakenStore from 'Pages/ServiceUsers/MedicationsTaken/store';

import NarrativeScheduleApi from 'Services/Api/ServiceUsers/NarrativeSchedule';
import RecordApi from 'Services/Api/Cms/Elements/Record';
import ServiceUserMedicationsTaken from 'Services/Api/ServiceUsers/ServiceUserMedicationsTaken';

import User from 'Services/User';
import {getRowActions} from 'Services/CmsHelpers';
import {translation} from 'Services/TranslationHelpers';
import {translationFromJson} from 'Services/TranslationHelpers2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default class Index extends React.Component {
    /**
     * @var tdClass
     * @type {string}
     */
    tdClass = 'text-left px-4 py-2 whitespace-nowrap'

    /**
     * @var state
     */
    state = {
        date: null,
        working: true,
        scheduleData: null,
        cmsRecordsData: null,
        sectionsVisibility: {
            morning: true,
            afternoon: true,
            evening: true,
        }
    };

    /**
     * @method componentDidMount
     */
    componentDidMount = async () => {
        await this.loadSchedule();

        this.setDate(new Date());

        RecordApi.on('updated', () => this.setDate(this.state.date));
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount = () => {
        RecordApi.removeListener('updated', this.setDate);
    };

    /**
     * @method setDate
     * @param {Date} date
     */
    setDate = async (date) => {
        this.setState({
            date,
            working: true
        }, async () => {
            await this.loadCmsRecords();
        });
    }

    /**
     * @method toggleSectionVisibility
     * @param {string} section
     */
    toggleSectionVisibility = (section) => {
        this.setState((prevState) => ({
            sectionsVisibility: {
                ...prevState.sectionsVisibility,
                [section]: !prevState.sectionsVisibility[section],
            }
        }));
    }

    /**
     * @method loadSchedule
     */
    loadSchedule = async () => {
        const {service_user_id} = this.props;

        const response = await NarrativeScheduleApi.get(null, {
            service_user_id,
            order_by: 'time',
            order: 'asc',
            paginate: false
        });

        this.setState({
            scheduleData: response.data.data
        });
    }

    /**
     * @method loadCmsRecords
     */
    loadCmsRecords = async () => {
        const {service_user_id} = this.props;

        const response = await RecordApi.get(null, {
            service_user_id,
            date_of_observation: this.getDateString(),
            paginate: false,
            order_by: 'date_of_observation',
            order: 'asc'
        });

        // Get the medication for today.
        const medicationRequest = await ServiceUserMedicationsTaken.get(null, {
            service_user_id,
            date_time: this.getDateString(),
        });

        const cmsRecordsData = [
                ...response.data.data?.map(item => ({
                ...item,
                type: 'narrative',
            })) ?? [],
            // ...medicationRequest.data.data?.map(item => ({
            //     ...item,
            //     type: 'medication',
            // })) ?? [],
        ];

        this.setState({
            cmsRecordsData,
            working: false
        });
    }

    /**
     * @method getDateString
     * @return {string}
     */
    getDateString = () => {
        return this.state.date.toISOString().split('T')[0];
    }

    /**
     * @method checkCmsRecordExists
     * @param {string} type
     * @param {uuid} cms_element_id
     * @return {boolean}
     */
    checkCmsRecordExists(type, cms_element_id) {
        const {cmsRecordsData} = this.state;

        let data = cmsRecordsData.filter(datum => {
            if (datum.cms_element_id !== cms_element_id) {
                return false;
            }

            return this.handleCmsRecordTypeCheck(type, datum);
        });

        if (!data || data.length === 0) {
            return false;
        }

        return true;
    }

    /**
     * @method checkCmsRecordExists
     * @param {string} type
     * @param {object} cmsRecord
     * @return {boolean}
     */
    handleCmsRecordTypeCheck(type, cmsRecord) {
        const date = cmsRecord.date_of_observation ?? cmsRecord.date_time;

        let hour = date?.time_timezone.split(':')[0];

        if (type === 'morning') {
            if (hour < 12) {
                return true;
            }
        } else if (type === 'afternoon') {
            if (hour >= 12 && hour < 17) {
                return true;
            }
        } else if (type === 'evening') {
             if (hour >= 17) {
                return true;
            }
        }

        return false;
    }

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {service_user_id} = this.props;
        const {date, working, sectionsVisibility} = this.state;

        if (working) {
            return <Loading />;
        }

        let titleClassName = 'text-app-grey font-bold text-lg uppercase mb-2';

        const colSpan = User.data.is_admin ? 6 : 5;

        return (
            <div>
            <div className="flex flex-row gap-8 items-center mb-8">
                <DatePicker
                    containerClassName="max-w-max"
                    selected={date}
                    onChange={date => this.setDate(date)}
                    dayInteralButtons={true}
                    backDayInteralButton={true}
                    forwardDayInteralButton={true}
                    nowButton={true}
                />

                <ModalTrigger
                    component={CreateCmsElementRecordModal}
                    props={{
                        showElementsDropdown: true,
                        service_user_id,
                        attach_to_service_user: true
                    }}
                >
                    <PrimaryButton
                        text="Add Narrative"
                    />
                </ModalTrigger>
            </div>

            <div className="mb-8">
                <ModalTrigger
                    component={MedicationTakenStore}
                    props={{
                        service_user_id,
                        date
                    }}
                >
                    <PrimaryButton
                        text={translation('service_users', 'record_medications_taken')}
                    />
                </ModalTrigger>
            </div>

            <div>
                <table className="min-w-full divide-y divide-gray-300 w-full">
                    <thead>
                    <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            TIME
                        </th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            ACTION
                        </th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            SUMMARY
                        </th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            STAFF MEMBER
                        </th>
                        {User.data.is_admin && (
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                CREATED
                            </th>
                        )}
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <th
                            colSpan={ colSpan }
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900 bg-white text-center relative"
                        >
                            <div className="flex justify-center items-center">
                                <span>Morning</span>
                                <button
                                    className="absolute right-5"
                                    onClick={ () => this.toggleSectionVisibility ( 'morning' ) }
                                >
                                    <FontAwesomeIcon icon={ sectionsVisibility.morning ? faChevronUp : faChevronDown } />
                                </button>
                            </div>
                        </th>
                    </tr>
                    { sectionsVisibility.morning && this.renderTable ( 'morning' ) }
                    </tbody>

                    <tbody>
                    <tr>
                        <th
                            colSpan={ colSpan }
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900 bg-white text-center relative"
                        >
                            <div className="flex justify-center items-center">
                                <span>Afternoon</span>
                                <button
                                    className="absolute right-5"
                                    onClick={ () => this.toggleSectionVisibility ( 'afternoon' ) }
                                >
                                    <FontAwesomeIcon icon={ sectionsVisibility.afternoon ? faChevronUp : faChevronDown } />
                                </button>
                            </div>
                        </th>
                    </tr>
                    { sectionsVisibility.afternoon && this.renderTable ( 'afternoon' ) }
                    </tbody>

                    <tbody>
                    <tr>
                        <th
                            colSpan={ colSpan }
                            className="px-3 py-3 text-left text-sm font-semibold text-gray-900 bg-white text-center relative"
                        >
                            <div className="flex justify-center items-center">
                                <span>Evening</span>
                                <button
                                    className="absolute right-5"
                                    onClick={ () => this.toggleSectionVisibility ( 'evening' ) }
                                >
                                    <FontAwesomeIcon icon={ sectionsVisibility.evening ? faChevronUp : faChevronDown } />
                                </button>
                            </div>
                        </th>
                    </tr>
                    { sectionsVisibility.evening && this.renderTable ( 'evening' ) }
                    </tbody>
                </table>
            </div>
        </div>
        );
    }

    /**
     * @method renderTable
     * @param {string} type
     * @return {JSX.Element}
     */
    renderTable( type ) {
        const { scheduleData, cmsRecordsData } = this.state;

        let filteredScheduleData = scheduleData.filter ( datum => {
            return ( datum.time === type && !this.checkCmsRecordExists(type, datum.cms_element_id));
        });

        let filteredCmsRecordsData =  cmsRecordsData.filter(datum => {
            return this.handleCmsRecordTypeCheck(type, datum);
        });

        if ((!filteredScheduleData || filteredScheduleData.length === 0) &&
            (!filteredCmsRecordsData || filteredCmsRecordsData.length === 0)) {
            return (
                <tr>
                    <td className={this.tdClass} colSpan={User.data.is_admin ? 6 : 5}>
                        No narratives scheduled or entered for the {type}.
                    </td>
                </tr>
            );
        }

        return (
            <>
                {filteredScheduleData && filteredScheduleData.length !== 0 && this.renderIncompleteSchedule(filteredScheduleData)}
                {filteredCmsRecordsData && filteredCmsRecordsData.length !== 0 && this.renderCmsRecords(filteredCmsRecordsData)}
            </>
        );
    }

    /**
     * @method renderIncompleteSchedule
     * @param {array} filteredScheduleData
     * @return {JSX.Element}
     */
    renderIncompleteSchedule(filteredScheduleData) {
        const {service_user_id} = this.props;

        return (
            <>
                {filteredScheduleData.map((datum, i) => (
                    <tr key={i}>
                        <td className={this.tdClass} />

                        <td className={this.tdClass}>
                            {translationFromJson(datum?.cms_element?.label)}
                        </td>

                        <td className={this.tdClass} />
                        <td className={this.tdClass} />

                        {User.data.is_admin && (
                            <td className={this.tdClass} />
                        )}

                        <td className={this.tdClass}>
                            <ModalTrigger
                                component={CreateCmsElementRecordModal}
                                props={{
                                    showElementsDropdown: false,
                                    element_id: datum.cms_element_id,
                                    service_user_id
                                }}
                            >
                                <PrimaryButton
                                    text="No entry. Click here to complete"
                                />
                            </ModalTrigger>
                        </td>
                    </tr>
                ))}
            </>
        );
    }

    /**
     * @method renderCmsRecords
     * @param {array} filteredCmsRecordsData
     * @return {JSX.Element}
     */
    renderCmsRecords(filteredCmsRecordsData) {
        const {service_user_id} = this.props;

        return (
            <>
                {filteredCmsRecordsData
                    .sort((a, b) => {
                        let aDate = DateTime.fromISO(a?.date_of_observation?.iso_string ?? a?.date_time?.iso_string);
                        let bDate = DateTime.fromISO(b?.date_of_observation?.iso_string ?? b?.date_time?.iso_string);

                        return aDate - bDate;
                    })
                    .map((datum, i) => (
                    <tr key={i}>
                        <td className={this.tdClass} valign="top">
                            {datum.type === 'narrative' && (
                                <>
                                    { DateTime.fromISO(datum?.date_of_observation?.iso_string).toLocaleString(DateTime.TIME_24_SIMPLE) }
                                </>
                            )}

                            {datum.type === 'medication' && (
                                <>
                                    { DateTime.fromISO(datum?.date_time?.iso_string).toLocaleString(DateTime.TIME_24_SIMPLE) }
                                </>
                            )}
                        </td>
                        <td className={this.tdClass} valign="top">
                            {datum.type === 'narrative' && (
                                <>{translationFromJson(datum?.element?.label)}</>
                            )}

                            {datum.type === 'medication' && (
                                <>Meds given</>
                            )}
                        </td>
                        <td className="text-left px-4 py-2 w-full" valign="top">
                            {datum.type === 'narrative' && (
                                <>{this.renderSummary(datum)}</>
                            )}
                            {datum.type === 'medication' && (
                                <>{datum?.notes}</>
                            )}
                        </td>
                        <td className={this.tdClass} valign="top">
                            { datum.user?.initials ?? datum.administered_by?.initials }
                        </td>

                        {User.data.is_admin && (
                            <td className={this.tdClass} valign="top">
                                { DateTime.fromISO(datum?.created_at?.iso_string).toLocaleString(DateTime.TIME_24_SIMPLE) }
                            </td>
                        )}

                        {datum.type === 'narrative' && (
                            <td className={this.tdClass} valign="top">
                                <div className="flex flex-row gap-6">
                                    <ModalTrigger
                                        component={CreateCmsElementRecordModal}
                                        props={{
                                            showElementsDropdown: false,
                                            element_id: datum.cms_element_id,
                                            element_record_id: datum.id,
                                            service_user_id
                                        }}
                                    >
                                        <SecondaryButton
                                            text="Completed. Click here to update"
                                        />
                                    </ModalTrigger>

                                    <ActionsButton
                                        buttons={getRowActions(datum.cms_element_id, datum)}
                                        layout="dropdown"
                                    />
                                </div>
                            </td>
                        )}

                        {datum.type === 'medication' && (
                            <td className={this.tdClass} />
                        )}
                    </tr>
                ))}
            </>
        );
    }

    /**
     * @method renderSummary
     * @param {object} record
     * @return {*}
     */
    renderSummary = (record) => {
        switch (translationFromJson(record?.element?.label)) {
            case 'Morning Summary':
                return this.morningSummary(record.values, record.service_user);
            case 'Afternoon Summary':
                return this.afternoonSummary(record.values, record.service_user);
            case 'Evening Summary':
                return this.eveningSummary(record.values, record.service_user);
            case 'Spot Check Form':
                return this.spotCheckForm(record.values, record.service_user);
            case 'Sleep':
                return this.sleep(record.values, record.service_user);
            case 'Activity Log':
                return this.activityLog(record.values, record.service_user);
            case 'Epilepsy':
                return this.epilepsy(record.values, record.service_user);
            case 'Challenging Behaviour':
                return this.challengingBehaviour(record.values, record.service_user);
            case 'Narrative':
                return this.narrative(record.values, record.service_user);
            case 'Meal':
                return this.meal(record.values, record.service_user);
            case 'Awake':
                return this.awake(record.values, record.service_user);
            case 'Personal Care':
                return this.personalCare(record.values, record.service_user);
            case 'Incident/Safeguarding Reporting Form':
                return this.incidentSafeguardingReportingForm(record, record.service_user);
        }
    };

    /**
     * @method morningSummary
     * @param {object} values
     * @return {*}
     */
    morningSummary = (values) => {
        return (
            <>
                {this.getRecordField(values, 'Morning Summary')?.value ?? ''}
            </>
        );
    };

    /**
     * @method afternoonSummary
     * @param {object} values
     * @return {*}
     */
    afternoonSummary = (values) => {
        return (
            <>
                {this.getRecordField(values, 'Afternoon Summary')?.value ?? ''}
            </>
        );
    };

    /**
     * @method eveningSummary
     * @param {object} values
     * @return {*}
     */
    eveningSummary = (values) => {
        return (
            <>
                {this.getRecordField(values, 'Evening Summary')?.value ?? ''}
            </>
        );
    };

    /**
     * @method spotCheckForm
     * @param {object} values
     * @return {*}
     */
    spotCheckForm = (values) => {
        const displayValues = [
            `${this.getRecordField(values, 'Care Worker Name')?.value} visited for {this.getRecordField(values, 'Duration of visit')?.value}`,
            this.getRecordField(values, 'Punctuality')?.value ? `${this.getRecordField(values, 'Punctuality')?.value} punctuality` : '',
            this.getRecordField(values, 'Personal Appearance')?.value ? `${this.getRecordField(values, 'Personal Appearance')?.value} personal appearance` : '',
            this.getRecordField(values, 'Politeness and consideration')?.value ? `${this.getRecordField(values, 'Politeness and consideration')?.value} politeness and consideration` : '',
            this.getRecordField(values, 'Ability in carrying out care')?.value ? `${this.getRecordField(values, 'Ability in carrying out care')?.value} ability in carrying out care` : '',
            this.getRecordField(values, 'Knowledge and skills')?.value ? `${this.getRecordField(values, 'Knowledge and skills')?.value} knowledge and skills` : '',
            this.getRecordField(values, 'Did the care worker show their ID badge?')?.value ? 'The care worker showed their badge' : '',
            this.getRecordField(values, 'Was the care worker wearing appropriate clothing?')?.value ? 'The care worker was wearing appropriate clothing' : '',
            this.getRecordField(values, 'Do you have any reasons to complain or praise about care worker?')?.value ? 'Had reason to complain about the care worker' : '',
            this.getRecordField(values, 'Any other details?')?.value ?? '',
            this.getRecordField(values, 'Report and actions to be taken')?.value ?? '',
        ];

        return (
            <>
                {displayValues.filter(value => value).map(item => (
                    <>
                        {item}<br />
                    </>
                ))}
            </>
        );
    };

    /**
     *
     * @param values
     * @param serviceUser
     * @returns {*}
     */
    sleep = (values, serviceUser) => {
        return (
            <>
                ${serviceUser?.initials} was asleep at {DateTime.fromISO(this.getRecordField(values, 'Asleep')?.value?.iso_string).toLocaleString(DateTime.TIME_24_SIMPLE)}<br />
            </>
        );
    };

    /**
     * @method activityLog
     * @param values
     * @returns {JSX.Element}
     */
    activityLog = (values) => {
        const displayValues = [
            this.getRecordField(values, 'Title')?.value ?? '',
            this.getRecordField(values, 'Describe engagement and duration')?.value ?? '',
        ];

        return (
            <>
                {displayValues.filter(value => value).map(item => (
                    <>
                        {item}<br />
                    </>
                ))}
            </>
        );
    };


    /**
     * @method epilepsy
     * @param values
     * @param serviceUser
     * @returns {JSX.Element}
     */
    epilepsy = (values, serviceUser) => {
        const displayValues = [
            `Occurred at ${DateTime.fromISO(this.getRecordField(values, 'Time of seizure')?.value?.iso_string).toLocaleString(DateTime.TIME_24_SIMPLE)}`,
            this.getRecordField(values, 'Any triggers?')?.value ?? '',
            this.getRecordField(values, 'Any warnings immediately before seizure?')?.value ?? '',
            this.getRecordField(values, 'Length of seizure')?.value ?? '',
            this.getRecordField(values, 'Was there a change to their breathing pattern?')?.value ? `${serviceUser?.initials}'s breathing changed` : '',
            this.getRecordField(values, 'Was there a change to their breathing pattern?')?.value ? (this.getRecordField(values, 'If yes, please give more information on change to breathing')?.value ?? '') : '',
            this.getRecordField(values, 'Did they loose awareness?')?.value ? `${serviceUser?.initials} lost awareness` : '',
            this.getRecordField(values, 'Did they appear dazed or confused')?.value ? `${serviceUser?.initials} appeared dazed or confused` : '',
            this.getRecordField(values, 'Did they loose consciousness?')?.value ? `${serviceUser?.initials} lost consciousness` : '',
            this.getRecordField(values, 'Did they loose consciousness?')?.value ? (this.getRecordField(values, 'If yes, please give details')?.value ?? '') : '',
            this.getRecordField(values, 'Change in muscle tone?')?.value ? `${serviceUser?.initials}'s muscle tone changed` : '',
            this.getRecordField(values, 'Change in muscle tone?')?.value ? (this.getRecordField(values, 'Please give details')?.value ?? '') : '',
            this.getRecordField(values, 'Did they fall?')?.value ? `${serviceUser?.initials} had a fall` : '',
            this.getRecordField(values, 'Did they fall?')?.value ? (this.getRecordField(values, 'If yes, please describe the fall')?.value ?? '') : '',
            this.getRecordField(values, 'Any injuries sustained?')?.value ? `${serviceUser?.initials} sustained injuries` : '',
            this.getRecordField(values, 'How were they afterwards?')?.value ?? '',
            this.getRecordField(values, 'How long did it take them to fully recover from seizure to normal activities?')?.value ?? '',
        ];

        return (
            <>
                {displayValues.filter(value => value).map(item => (
                    <>
                        {item}<br />
                    </>
                ))}
            </>
        );
    };

    /**
     * @method challengingBehaviour
     * @param {object} values
     * @param {object} serviceUser
     * @return {JSX.Element}
     */
    challengingBehaviour = (values, serviceUser) => {
        const displayValues = [
            this.getRecordField(values, 'What happened immediately before the behavioural outburst? Include any triggers, signs of distress or environmental information.')?.value ?? '',
            this.getRecordField(values, 'What happened during the outburst? What behaviour was displayed "looked like"?')?.value ?? '',
            this.getRecordField(values, 'Any physical aggression?')?.value ? `${serviceUser?.initials} showed physical aggression` : '',
            this.getRecordField(values, 'Targeted behaviour towards an individual?')?.value ? `${serviceUser?.initials}'s behaviour was targeted towards an individual` : '',
            this.getRecordField(values, 'Did staff have to intervene')?.value ? 'Staff intervened' : '',
            this.getRecordField(values, 'What happened immediately after the behaviour? Include other people\'s responses to the behaviour and eventual outcome')?.value ?? '',
            this.getRecordField(values, 'First aid administered?')?.value ? 'First aid was administered' : '',
        ];

        return (
            <>
                {displayValues.filter(value => value).map(item => (
                    <>
                        {item}<br />
                    </>
                ))}
            </>
        );
    };

    /**
     * @method narrative
     * @param {object} values
     * @returns {JSX.Element}
     */
    narrative = (values) => {
        return (
            <>
                {this.getRecordField(values, 'Details of session')?.value}
            </>
        );
    };

    /**
     * @method medication
     * @param values
     * @param serviceUser
     * @returns {JSX.Element}
     */
    meal = (values, serviceUser) => {
        const displayValues = [
            this.getRecordField(values, 'Did they eat?')?.value ? `${serviceUser?.initials} ate` : '',
            this.getRecordField(values, 'Food')?.value ?? '',
            this.getRecordField(values, 'Did they eat a full portion?')?.value ? `${serviceUser?.initials} ate a full portion` : '',
            this.getRecordField(values, 'Did they drink?')?.value ? `${serviceUser?.initials} drank` : '',
            this.getRecordField(values, 'Drink')?.value ?? '',
            this.getRecordField(values, 'Comments')?.value ?? '',
        ];

        return (
            <>
                {displayValues.filter(value => value).map(item => (
                    <>
                        {item}<br />
                    </>
                ))}
            </>
        );
    };

    /**
     * @method awake
     * @param {object} values
     * @returns {JSX.Element}
     */
    awake = (values) => {
        return (
            <>
                {this.getRecordField(values, 'Comments')?.value}<br />
            </>
        );
    };

    /**
     * @method personalCare
     * @param {object} values
     * @param {object} serviceUser
     * @returns {JSX.Element}
     */
    personalCare = (values, serviceUser) => {
        const displayValues = [
            this.getRecordField(values, 'Washed hair')?.value ? `${serviceUser?.initials}'s hair was washed${ this.getRecordFieldById(values, 'd48627cd-6ccf-4166-a00f-9b529fc5cd77')?.value ? ` - ${ this.getRecordFieldById(values, 'd48627cd-6ccf-4166-a00f-9b529fc5cd77')?.value }` : '' }` : '',
            this.getRecordField(values, 'Wash body')?.value ? `${serviceUser?.initials}'s body was washed${ this.getRecordFieldById(values, '2afc47a5-05ae-4137-a203-fa24681e9226')?.value ? ` - ${ this.getRecordFieldById(values, '2afc47a5-05ae-4137-a203-fa24681e9226')?.value }` : ''}` : '',
            this.getRecordField(values, 'Teeth brushed')?.value ? `${serviceUser?.initials}'s teeth were brushed${this.getRecordFieldById(values, '2afc47a5-05ae-4137-a203-fa24681e9226')?.value ? ` - ${this.getRecordFieldById(values, 'c614aecc-7371-4d80-a39c-21d78643cc65')?.value}` : ''}` : '',
            this.getRecordField(values, 'Stool passed')?.value ? `${serviceUser?.initials} passed stool` : '',
            this.getRecordField(values, 'Urination')?.value ? `${serviceUser?.initials} urinated` : '',
            this.getRecordField(values, 'Any other?')?.value,
            this.getRecordField(values, 'Comments')?.value,
        ];

        return (
            <>
                {displayValues.filter(value => value).map(item => (
                    <>
                        {item}<br />
                    </>
                ))}
            </>
        );
    };

    /**
     * @method incidentSafeguardingReportingForm
     * @param datum
     * @param serviceUser
     * @returns {JSX.Element}
     */
    incidentSafeguardingReportingForm = (datum, serviceUser) => {
        return (
            <>
                <ModalTrigger
                    component={CreateCmsElementRecordModal}
                    props={{
                        showElementsDropdown: false,
                        element_id: datum.cms_element_id,
                        element_record_id: datum.id,
                        service_user_id: serviceUser.id,
                    }}
                >
                    Please click here to view the full report
                </ModalTrigger>
            </>
        );
    };

    /**
     * @method getRecordField
     * @param {object} values
     * @param {string} field
     * @return {*}
     */
    getRecordField = (values, field) => values?.find(value => translationFromJson(value?.field?.label) === field);

    /**
     * @method getRecordFieldById
     * @param {object} values
     * @param {string} id
     * @returns {*}
     */
    getRecordFieldById = (values, id) => values?.find(value => value?.field_id === id);
}
