import React from 'react';
import { DateTime } from "luxon";

import {IndexTable} from 'Components/Utilities';
import {PaginationBar} from 'Components/Partials';

import {convertValue} from 'Services/BaseHelpers';
import { ModalTrigger } from "Components/Modal";
import RatesBreakdownModal from "Components/HumanResources/Timesheets/RatesBreakdownModal";

export default class TimesheetsIndexTable extends IndexTable {
    /**
     * @method calculateTotals
     * @return {object}
     */
    calculateTotals = () => {
        const {data} = this.state;

        let totals = {};

        const hoursData = [];
        [...data.data].sort((a, b) => a.hours.hours < b.hours.hours ? 1 : -1).forEach((r) => {
            if (r.type === 'time_worked') {
                const s = DateTime.fromISO(r.start_date_time.iso_string).toSeconds();
                const e = DateTime.fromISO(r.end_date_time.iso_string).toSeconds();

                // console.log(r);
                const c = hoursData.find((item) => {
                    return DateTime.fromISO(item.start_date_time.iso_string).toSeconds() < e &&
                        DateTime.fromISO(item.end_date_time.iso_string).toSeconds() > s
                });

                if (!c) {
                    hoursData.push(r);
                }
            }
        });

        // hours
        let hours = hoursData.reduce((hours, record) => {
            if (!record.hours) {
                return hours;
            }

            return {
                hours: +hours.hours + +record.hours.hours,
                mins: +hours.mins + +record.hours.mins,
            };
        }, {
            hours: 0,
            mins: 0
        });

        if (hours.mins > 59) {
            let hours_to_add = Math.floor(hours.mins / 60);

            hours = {
                hours: +hours.hours + +hours_to_add,
                mins: +hours.mins - +(hours_to_add * 60)
            };
        }

        totals.hours = hours;

        // Miles
        totals.mileage = data.data.reduce((mileage, record) => mileage + Number(record.mileage ?? 0), 0);

        // Sleeps In
        totals.sleeps_in = data.data.reduce((sleepsIn, record) => sleepsIn + Number(record.sleeps_in ?? 0), 0);

        return totals;
    }

    /**
     * @method renderTable
     * @return {JSX.Element}
     */
    renderTable() {
        const {columns, rowActions} = this.props;
        const {data} = this.state;

        let total = this.getTotal();

        return (
            <div className={`h-full flex flex-col ${total !== 0 ? 'justify-between' : 'justify-end'}`}>
                {total !== 0 &&
                    <div className="overflow-x-auto">
                        <table className="table-auto mx-auto">
                            <thead>
                                <tr className={this.classNames.headerRow}>
                                    {columns.map((column, i) => this.renderColumnHeader(column, i))}

                                    {this.renderColumnSelect()}
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.map((datum, i) => {
                                    return (
                                        <tr className={this.classNames.row(i)} key={i}>
                                            {columns.map((column, j) => this.renderColumnValue(datum, column, i, j))}

                                            {rowActions && this.renderRowActions(datum)}
                                        </tr>
                                    );
                                })}

                                {this.renderTotals()}
                            </tbody>
                        </table>
                    </div>
                }

                {data?.meta &&
                    <div className="mt-12">
                        <PaginationBar
                            total={data.meta.total}
                            pageCount={data.meta.last_page}
                            page={data.meta.current_page}
                            goToPage={this.loadData}
                        />
                    </div>
                }
            </div>
        );
    }

    /**
     * @method renderTotals
     * @return {JSX.Element}
     */
    renderTotals(records) {
        const {columns, showTotal = true} = this.props;
        const {data, activeColumns} = this.state;

        if (!showTotal) {
            return;
        }

        let totals = this.calculateTotals();

        return (
            <tr>
                {columns.filter((column) =>
                    this.shouldShowColumn(column) && activeColumns.includes(column.value)
                ).map((column, i) => (
                    <td
                        className={['Hours', 'Total Rate', 'Mileage', 'Sleeps In'].includes(column.label) ?
                            this.classNames.headerRow + ' ' + this.classNames.cell :
                            null}
                        title={column.label === 'Hours' ? "Total Shift & Annual Leave hours" : null}
                        key={i}
                    >
                        {column.label === 'Hours' &&
                            totals.hours ? (totals.hours.hours + 'h ' + totals.hours.mins + 'm') : null
                        }

                        {column.label === 'Mileage' && (totals.mileage ?? null)}

                        {column.label === 'Sleeps In' && (totals.sleeps_in ?? null)}

                        {column.label === 'Total Rate' &&
                            <ModalTrigger
                                component={RatesBreakdownModal}
                                props={{
                                    timesheetIds: data.data.map((record) => record.id),
                                }}
                            >
                                {'£' + (totals.total_rate ? Number.parseFloat(totals.total_rate).toFixed(2) : 0.00)}
                            </ModalTrigger>
                        }
                    </td>
                ))}
            </tr>
        );
    }

    /**
     * @method renderColumnHeader
     * @param {object} record
     * @param {object} column
     * @param {integer} key1
     * @param {integer} key2
     * @return {JSX.Element}
     */
    renderColumnValue(record, column, key1, key2) {
        // Only display each date once.
        if (column.label === 'Date') {
            const {data} = this.state;

            if (data.data[key1 - 1]) {
                let previousDate = convertValue(column, data.data[key1 - 1]);
                let thisDate = convertValue(column, record);

                if (previousDate === thisDate) {
                    return (
                        <td
                            className={column.valueClassNameOverwrite ? column.valueClassName : `${this.classNames.cell} ${column.valueClassName}`}
                            key={key2}
                        />
                    );
                }
            }
        }

        const {activeColumns} = this.state;

        // Check if active column.
        if (!activeColumns.includes(column.value)) {
            return null;
        }

        return (
            <td
                className={column.valueClassNameOverwrite ? column.valueClassName : `${this.classNames.cell} ${column.valueClassName}`}
                key={key2}
            >
                {convertValue(column, record)}
            </td>
        );
    }
}
