import React from 'react';

import {Alert} from 'Components/Partials';
import {PrimaryButton} from 'Components/Button';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'Components/Modal';
import { FormHandler, Input, Textarea, Select, DatePicker, Toggle } from 'Components/Form';

import MedicationsApi from 'Services/Api/ServiceUsers/ServiceUserMedications';
import { DateTimeFilter } from "Components/Filters";
import { DateTime } from "luxon";

class Store extends React.Component {
    /**
     * @var success
     * @type {string}
     */
    success = this.props.medication ? 'Successfully updated.' : 'Successfully added.';

    /**
     * @var times
     * @type {array}
     */
    times = [
        {
            label: 'Morning',
            value: 'morning'
        },
        {
            label: 'Afternoon',
            value: 'afternoon'
        },
        {
            label: 'Evening',
            value: 'evening'
        }
    ];

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.medication) {
            const { medication } = this.props;

            this.props.setForm({
                ...medication,
                start_date: medication?.start_date ? new Date(medication.start_date?.iso_string) : null,
                end_date: medication?.end_date ? new Date(medication.end_date?.iso_string) : null,
                medication_time: medication?.medication_time ? DateTime.fromFormat(medication.medication_time, "HH:mm:ss").toJSDate() : null,
            });
        }
    }

    /**
     * @method handleSubmit
     * @param {object} form
     * @return {Promise<void>}
     */
    handleSubmit = (form) => {
        const {service_user_id, medication} = this.props;

        form = {
            ...form,
            service_user_id,
            medication_time: form.medication_time ? DateTime.fromJSDate(form.medication_time).toFormat('HH:mm:ss') : null,
        };

        if (medication) {
            return MedicationsApi.patch(medication.id, form);
        } else {
            return MedicationsApi.post(null, form);
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    render() {
        const {medication, form, handleInput, handleSubmit, working, alert} = this.props;

        console.log(form);

        return (
            <Modal>
                <ModalHeader onClose={this.props.onClose}>
                    {medication ? 'Update' : 'Create'} Service User Medication
                </ModalHeader>

                <form onSubmit={(e) => handleSubmit(e, this.handleSubmit, this.success, !medication)}>
                    <ModalBody>
                        {alert !== null && (<Alert {...alert} />)}

                        <Input
                            containerClassName="mb-4"
                            label="Name"
                            value={form.name}
                            onChange={v => handleInput('name', v)}
                        />

                        <Input
                            containerClassName="mb-4"
                            label="Dosage / Strength"
                            value={form.dosage}
                            onChange={v => handleInput('dosage', v)}
                            instructions="(e.g., 2x 200mg tablets)"
                        />

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <Select
                                containerClassName="mb-4"
                                label="Time"
                                value={form.time}
                                onChange={(v) => handleInput('time', v)}
                                options={this.times}
                            />

                            <DatePicker
                                containerClassName="mb-4"
                                label="Medication Time"
                                selected={form.medication_time}
                                onChange={date => handleInput('medication_time', date)}
                                showTimeSelectOnly={true}
                                showTimeSelect={true}
                                timeIntervals={5}
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <DatePicker
                                containerClassName="mb-4"
                                label="Start Date"
                                selected={form.start_date}
                                onChange={date => handleInput('start_date', date)}
                                selectsStart
                                startDate={form.start_date}
                                endDate={form.end_date}
                            />

                            <DatePicker
                                containerClassName="mb-4"
                                label="End Date"
                                selected={form.end_date}
                                onChange={date => handleInput('end_date', date)}
                                selectsEnd
                                startDate={form.start_date}
                                endDate={form.end_date}
                                minDate={form.start_date}
                            />
                        </div>

                        <Input
                            containerClassName="mb-4"
                            label="Method"
                            value={form.method}
                            onChange={v => handleInput('method', v)}
                            instructions="(e.g., oral with a drink of milk)"
                        />

                        <Input
                            containerClassName="mb-4"
                            label="What is it for"
                            value={form.what_is_it_for}
                            onChange={v => handleInput('what_is_it_for', v)}
                            instructions="(e.g., Epilepsy)"
                        />

                        <Input
                            containerClassName="mb-4"
                            label="Side Effects"
                            value={form.side_effects}
                            onChange={v => handleInput('side_effects', v)}
                            instructions="(e.g., nausea)"
                        />

                        <Textarea
                            containerClassName="mb-4"
                            label="Specific Medication Requirements"
                            value={form.specific_requirements}
                            onChange={v => handleInput('specific_requirements', v)}
                            instructions="(e.g., no pineapple juice)"
                        />

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <Toggle
                                label="PRN"
                                value={form.prn}
                                onChange={v => handleInput('prn', v)}
                            />
                            <Toggle
                                label="Non Prescription"
                                value={form.non_prescription}
                                onChange={v => handleInput('non_prescription', v)}
                            />
                        </div>
                    </ModalBody>

                    <ModalFooter alignment="center">
                        <PrimaryButton
                            text={medication ? 'Update' : 'Create'}
                            working={working}
                        />
                    </ModalFooter>
                </form>
            </Modal>
        );
    }
}

export default FormHandler(Store);
